import React, { Component } from 'react';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { Switch, Route, NavLink, withRouter } from 'react-router-dom';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import AdminLink from './AdminLink';

import {
	boxLayoutAction, toggleLeftMenuAction
} from '../../redux/actions/settingsAction';
import Routes from '../Route';
import { SettingsComponent } from './Settings';
import { AdminMenu, CoopMenu, EShopMenu, MemberMenu, ReportsMenu } from '../../utils/menu-items';
import { UserSideBar } from './UserSideBar';
import { updateRole } from '../../redux/slices/user.slice';
import { setActiveCoop } from '../../redux/slices/cooperative.slice';
import { toggleLeftMenu } from '../../redux/slices/setting.slice';


const masterNone = {
	display: 'none',
};

const masterBlock = {
	display: 'block',
};

class Menu extends Component {
	constructor(props) {
		super(props);
		this.toggleLeftMenu = this.toggleLeftMenu.bind(this);
		this.toggleUserMenu = this.toggleUserMenu.bind(this);
		this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
		this.toggleSubMenu = this.toggleSubMenu.bind(this);
		this.handleBoxLayout = this.handleBoxLayout.bind(this);
		this.handler = this.handler.bind(this);
		this.changeMenu = this.changeMenu.bind(this)
		this.state = {
			isToggleLeftMenu: false,
			isOpenUserMenu: false,
			isOpenRightSidebar: false,
			isBoxLayout: false,
			parentlink: null,
			childlink: null,
		};

	}

	componentDidMount() {
		const { location } = this.props;
		const links = location.pathname.substring(1).split(/-(.+)/);
		const parentlink = links[0];
		const nochildlink = links[1];

		if (parentlink && nochildlink && nochildlink === 'dashboard') {
			this.handler(parentlink, `${parentlink}${nochildlink}`);
		} else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
			this.handler(parentlink, nochildlink);
		} else if (parentlink) {
			this.handler(parentlink, '');
		} else {
			this.handler('hr', 'dashboard');
		}
	}

	componentDidUpdate(prevprops, prevstate) {
		const { location } = this.props;
		const links = location.pathname.substring(1).split(/-(.+)/);
		const parentlink = links[0];
		const nochildlink = links[1];
		if (prevprops.location !== location) {
			if (parentlink && nochildlink && nochildlink === 'dashboard') {
				this.handler(parentlink, `${parentlink}${nochildlink}`);
			} else if (parentlink && nochildlink && nochildlink !== 'dashboard') {
				this.handler(parentlink, nochildlink);
			} else if (parentlink) {
				this.handler(parentlink, '');
			} else {
				this.handler('hr', 'dashboard');
			}
		}
	}

	handler(parentlink, nochildlink) {
		this.setState({
			parentlink: parentlink,
			childlink: nochildlink,
		});
		// if(this.props.activeCoop) {
			// this.props.changeActiveCoop(null);
        	// alertify.success(`You are now back to Admin Dashboard.`)
		// }

	}

	handleBoxLayout(e) {
		this.props.boxLayoutAction(e.target.checked)
	}
	toggleLeftMenu(e) {
		this.props.toggleLeftMenuAction(e)
	}
	toggleRightSidebar() {
		this.setState({ isOpenRightSidebar: !this.state.isOpenRightSidebar })
	}
	toggleUserMenu() {
		this.setState({ isOpenUserMenu: !this.state.isOpenUserMenu })
	}
	toggleSubMenu(e) {
		let menucClass = ''
		if (e.itemId) {
			const subClass = e.items.map((menuItem, i) => {
				if (menuItem.to === this.props.location.pathname) {
					menucClass = "in";
				} else {
					menucClass = "collapse";
				}
				return menucClass
			})
			return subClass
			// return "collapse";
		} else {
			return e.visible ? "collapse" : "metismenu";
		}
	}
	changeMenu(role) {
		if (role === 'eshop') 
			this.props.changeActiveMenu(this.props.user.role_name);


		this.props.history.push('/dashboard')
		
	}

	render() {
		
		const { isOpenRightSidebar, isOpenUserMenu } = this.state
		const { darkMinSidebar, istoggleLeftMenu, role, activeCoop, user, colors } = this.props;
		const pageHeading = Routes.filter((route) => route.path === this.props.location.pathname)

		console.log(istoggleLeftMenu)
		return (
			<>
				<div className={`${istoggleLeftMenu ? "offcanvas-active" : ""}`}>
					<div style={this.state.parentlink === 'login' ? masterNone : masterBlock}>
						<div id="header_top" style={{backgroundColor: colors?.secondary_color}} className={`header_top ${darkMinSidebar && 'dark'}`}>
							<div className="container">
								<div className="hleft">
									<NavLink
										to="/dashboard"
										onClick={() => { 
											this.changeMenu(role);
											this.handler('hr', 'dashboard');
										}}
										className=""
									>
										{activeCoop ? 
											<img src={activeCoop?.logo || "../assets/images/calicon-icon.png"} className='avatar' />
										:
											<img src="../assets/images/calicon-icon.png" className='avatar' />
										}
									</NavLink>
									<div className="dropdown">
										<NavLink 
											onClick={() => this.changeMenu(role)}
										 	to="/dashboard" className="nav-link icon"
										>
											<i className="fa fa-home" />
										</NavLink>
										
										{activeCoop && role == 'cooperative-admin' &&
											<NavLink 
												to="/members" className="nav-link icon xs-hide">
												<i className="fa fa-id-card-o" />
											</NavLink>
										}
										{activeCoop && 
											<NavLink 
												to="/app-chat" className="nav-link icon xs-hide"
											>
												<i className="fa fa-comments-o" />
											</NavLink>
										}
										<NavLink 
											to={(role === 'cooperative-member' || role === 'cooperative-executive') ? '/member/elections' : '/setup/polls'} className="nav-link icon app_inbox">
											<i className="icon-like" />
										</NavLink>
										<a href='https://calicon-store.netlify.app' target='_blank' className="nav-link icon app_file xs-hide">
											<i className="icon-handbag" />
										</a>
										
									</div>
								</div>
								<div className="hright">
									<div className="dropdown">
										{/* <a href="#!" className="nav-link icon theme_btn">
										<i
											className="fa fa-paint-brush"
											data-toggle="tooltip"
											data-placement="right"
											title="Themes"
										></i>
									</a> */}
										<span className="nav-link icon settingbar" onClick={this.toggleRightSidebar}>
											<i
												className="fa fa-gear fa-spin"
												data-toggle="tooltip"
												data-placement="right"
												title="Settings"
											/>
										</span>
										{user?.role_name == 'super-admin' &&
										<p className="nav-link user_btn" onClick={this.toggleUserMenu}>
											<i className="fa fa-group" />
										</p>}
										<p className="nav-link icon menu_toggle" onClick={() => this.toggleLeftMenu(!istoggleLeftMenu)}>
											<i className="fa fa-align-left" />
										</p>
									</div>
								</div>
							</div>
						</div>
						<SettingsComponent 
							toggleRightSidebar={this.toggleRightSidebar} 
							isOpenRightSidebar={isOpenRightSidebar}
						/>
						<div className="theme_div">
							<div className="card">
								<div className="card-body">
									<ul className="list-group list-unstyled">
										<li className="list-group-item mb-2">
											<p>Default Theme</p>
											<a href="../main/index.html">
												<img
													src="/assets/images/themes/default.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Night Mode Theme</p>
											<a href="../dark/index.html">
												<img
													src="/assets/images/themes/dark.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>RTL Version</p>
											<a href="../rtl/index.html">
												<img
													src="/assets/images/themes/rtl.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version2</p>
											<a href="../theme2/index.html">
												<img
													src="/assets/images/themes/theme2.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version3</p>
											<a href="../theme3/index.html">
												<img
													src="/assets/images/themes/theme3.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Theme Version4</p>
											<a href="../theme4/index.html">
												<img
													src="/assets/images/themes/theme4.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
										<li className="list-group-item mb-2">
											<p>Horizontal Version</p>
											<a href="../horizontal/index.html">
												<img
													src="/assets/images/themes/horizontal.png"
													className="img-fluid"
													alt="fake_url"
												/>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						
						<UserSideBar isOpenUserMenu={isOpenUserMenu} toggleUserMenu={this.toggleUserMenu} />

						<div id="left-sidebar" className="sidebar" style={{backgroundColor: colors?.primary_color}}>
							<h5 className="brand-name text-primary">
								{!activeCoop ? 
								 	<img src="../assets/images/calicon-logo.png" height={30} className='' />
								: 
									activeCoop.name
								}
							</h5>
							<nav id="left-sidebar-nav" className="sidebar-nav">
									<MetisMenu className=""
										content={{
											'cooperative-admin': CoopMenu,
											'cooperative-accountant': CoopMenu,
											'cooperative-executive': MemberMenu,
											'cooperative-member': MemberMenu,
											eshop: EShopMenu,
											report: ReportsMenu
										}[role] || AdminMenu}
										noBuiltInClassNames={true}
										classNameContainer={(e) => this.toggleSubMenu(e)}
										classNameContainerVisible="in"
										classNameItemActive="active"
										classNameLinkActive="active"
										// classNameItemHasActiveChild="active"
										classNameItemHasVisibleChild="active"
										classNameLink="has-arrow arrow-c"
										// classNameIcon
										// classNameStateIcon

										iconNamePrefix=""
										// iconNameStateHidden=""
										LinkComponent={(e) => <AdminLink itemProps={e} />}
									// toggleSubMenu={this.toggleSubMenu}
									/>
								

							</nav>
						</div>
					</div>

					<div className="page">
						<Header dataFromParent={this.props.dataFromParent} dataFromSubParent={pageHeading[0]?.pageTitle} />
						<Switch>
							{Routes.map((layout, i) => {
								return <Route key={i} exact={layout.exact} path={layout.path} component={layout.component} />
							})}
						</Switch>
						<Footer />
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => ({
	darkMinSidebar: state.settings.isMinSidebar,
	istoggleLeftMenu: state.settings.isToggleLeftMenu,
	role: state.user.role,
	user: state.user.user,
	activeCoop: state.coop.activeCoop,
	colors: state.ui.colors,
})

const mapDispatchToProps = dispatch => ({
	boxLayoutAction: (e) => dispatch(boxLayoutAction(e)),
	toggleLeftMenuAction: (e) => dispatch(toggleLeftMenu(e)),
	changeActiveMenu: (role) => dispatch(updateRole(role)),
	changeActiveCoop: (val) => dispatch(setActiveCoop(val)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));