import React from "react";
import { SavingsChangeRequestForm } from "../../MemberArea/Components/SavingsChangeRequestForm";
import { AddNewSavings } from "../../MemberArea/Components/AddNewSavings";
import { Empty, Tooltip } from "antd";
import { formatNumber } from "../../../utils/helpers";
import Card from "../../common/Card";

export const SavingsTable = ({coop, memberId, savings, loading, role}) => {
    return (
        <>
            <Card 
                loading={loading}
                headerOptions={
                    <div className="card-options">
                        <form>
                            <div className="input-group">
                                <button type="button" className="btn btn-secondary mr-2" data-toggle="modal" data-target="#change-request">
                                    <i className="fa fa-chain-broken mr-2" /> Change Savings Amount
                                </button>
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#new-savings-modal">
                                    <i className="fe fe-plus-circle" /> Add New Plan
                                </button>
                            </div>
                        </form>
                    </div>
                }
            >
                <div className="card-body dimmer-content">
                    <div className="table-responsive">
                        <table className="table table-striped table-vcenter table-hover mb-0">
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Ledger Bal.</th>
                                    <th>Available Bal.</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {savings?.length > 0 ?
                                    savings.map(row => 
                                    <tr key={`savings-${row.id}`}>
                                        <td>{row.product.product.name}</td>
                                        <td>{row.start_date || 'N/A' }</td>
                                        <td>{row.end_date || 'N/A'}</td>
                                        <td><div className="font-15">{formatNumber(row.balance)}</div></td>
                                        <td><div className="font-15">{formatNumber(row.balance - row.lien)}</div></td>
                                        <td>
                                            <Tooltip title="Deposit">
                                                <button type="button" className="btn btn-icon text-success" data-toggle="modal" data-target="#deposit-modal" title="Edit"><i className="fa fa-download" /></button>
                                            </Tooltip>
                                            {role !== 'cooperative-member' &&
                                                <Tooltip title="Withdraw">
                                                    <button type="button" className="btn btn-icon text-danger" data-toggle="modal" data-target="#withdrawal-modal" title="Edit"><i className="fa fa-upload" /></button>
                                                </Tooltip>
                                            }
                                        </td>
                                    </tr>)
                                :
                                    <tr>
                                        <td colSpan={6}><Empty /></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Card>
            <SavingsChangeRequestForm savings={savings} coop_id={memberId} cooperative_id={coop?.id}  />
            <AddNewSavings coop_id={memberId} cooperative_id={coop?.id} />
        </>
    )
}