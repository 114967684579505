import React, { useEffect, useState } from "react";
import { Tabs } from 'antd';
import { StaffInfo } from "../Forms/StaffInfo";
import { WorkInfo } from "../Forms/WorkInfo";
import { BankDetails } from "../Forms/BankDetails";
import { NokDetails } from "../Forms/NoKDetails";
import { EmergencyContact } from "../Forms/EmergencyContact";
import alertify from "alertifyjs";
import Card from "../../common/Card";
import { useEditMemberDetailsMutation, useUploadDocumentMutation } from "../../../services/member.service";
import Button from 'rsuite/Button';
import dayjs from "dayjs";
import { DocumentsForm } from "../Forms/Documents";

export const EditMemberForm = ({activeCoop, member}) => {
    const [step, setStep] = React.useState(0);
    const [isChanging, setIsChanging] = React.useState(false);
    const [editType, setEditType] = React.useState('profile');
    const [documentData, setDocumentData] = React.useState({
        avatar: null,
        signature: null,
        reg_form: null
    })

    const [save, {isLoading, isSuccess, isError, data, error}] = useEditMemberDetailsMutation();
    const [doUpload, {
        isLoading: isUploading, 
        isSuccess: uploadSuccess, 
        isError: uploadError, 
        data: uploadData, 
        error: uploadErrData
    }] = useUploadDocumentMutation();

    const onChange = nextStep => {
        setStep(nextStep < 0 ? 0 : nextStep > 4 ? 4 : nextStep);
        setIsChanging(false);
    };

    // console.log(member);

    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    const [formData, setFormData] = useState(
        {
            cooperative_id: activeCoop?.id,
            email: "",
            firstname: "",
            middlename: "",
            lastname: "",
            staff_id: "",
            date_joined: "",
            marital_status:  "",
            gender:  "",
            dob: "",
            religion: "",
            current_address:  "",
            role_id: "",
            savings_amount: "",
            country_id: 0,
            state_id: 0,
            level: "",
            department_id: "",
            official_email: "",
            official_phone: "",
            bank_id: "",
            branch: "",
            sort_code: "",
            account_type: "",
            account_name: "",
            account_no:  "",
            nok_name: "",
            nok_address: "",
            nok_phone:  "",
            nok_email: "",
            contact_name: "",
            contact_address:  "",
            contact_phone: "",
            contact_email: ""
        }
    )

    const doSubmit = () => {
        if (editType === 'profile') {
            const payload = {...formData};
            payload.coop_id = member?.coop_id;
            payload.date_joined = dayjs(formData.date_joined).format('YYYY-MM-DD')
            payload.dob = dayjs(formData.dob).format('YYYY-MM-DD')
            save(payload);   
        } else {

            const formData = new FormData();
            formData.append('coop_id', member?.coop_id);

            if (documentData.avatar)
                formData.append('avatar', documentData.avatar);
            if(documentData.reg_form)
                formData.append('reg_form', documentData.reg_form);
            if(documentData.signature)
                formData.append('signature', documentData.signature);
    
            doUpload(formData);
        }
    }

    
    useEffect(() => {
        if(isSuccess && data?.status) {
            // resetForm();
            alertify.success(data?.message)
        }

        if (isError) {
            //showToast()
            alertify.error(error?.data.message)
        }
    }, [isSuccess, isError, data]);

    useEffect(() => {
        if(uploadSuccess && uploadData?.status) {
            // resetForm();
            alertify.success(uploadData?.message)
        }

        if (uploadErrData) {
            //showToast()
            alertify.error(uploadErrData?.data.message)
        }
    }, [uploadSuccess, uploadErrData, uploadData]);


     useEffect(() => {
        if (member) 
            setFormData({
                cooperative_id: activeCoop?.id,
                detail_type: 'STAFF-INFO',
                email: member?.user?.email || "",
                phone: member?.phone || "",
                firstname: member?.firstname || "",
                middlename: member?.middlename || "",
                lastname: member?.lastname || "",
                staff_id: member?.staff_id || "",
                date_joined: member?.date_joined || "",
                marital_status: member?.marital_status || "",
                gender: member?.gender || "",
                dob: member?.dob || "",
                religion: member?.religion || "",
                current_address: member?.current_address || "",
                role_id: member?.role_id || "",
                savings_amount: member?.savings_amount || "",
                country_id: member?.country_id || 0,
                state_id: member?.state_id || 0,
                level: member?.workInfo.level || "",
                department_id: member?.workInfo.department_id || "",
                official_email: member?.workInfo.official_email || "",
                official_phone: member?.workInfo.official_email || "",
                bank_id: member?.bankInfo.bank_id || "",
                branch: member?.bankInfo.branch || "",
                sort_code: member?.bankInfo.sort_code || "",
                account_type: member?.bankInfo.account_type || "",
                account_name: member?.bankInfo.account_name || "",
                account_no: member?.bankInfo.account_no || "",
                nok_name: member?.nokInfo.name || "",
                nok_address: member?.nokInfo.address || "",
                nok_phone: member?.nokInfo.phone || "",
                nok_email: member?.nokInfo.email || "",
                contact_name: member?.emergencyInfo.name || "",
                contact_address: member?.emergencyInfo.address || "",
                contact_phone: member?.emergencyInfo.phone || "",
                contact_email: member?.emergencyInfo.email || ""
            })
    }, [member]);

    const tabs = [
        {
          key: '1',
          label: 'Staff Info',
          children: <StaffInfo onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={onNext} formData={formData} details={member} />
        },
        {
          key: '2',
          label: 'Work',
          children: <WorkInfo coop={activeCoop} onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} formData={formData} canSubmit={onNext} details={member} />,
        },
        {
          key: '3',
          label: 'Bank',
          children: <BankDetails onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={onNext} formData={formData} details={member} />,
        },
        {
            key: '4',
            label: 'Next of Kin',
            children: <NokDetails onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={onNext} formData={formData} details={member} />,
        },
        {
            key: '5',
            label: 'Emergency',
            children: <EmergencyContact onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={doSubmit} formData={formData} details={member} />,
        },
        {
            key: '6',
            label: 'Documents',
            children: <DocumentsForm onChange={(field, value) => setDocumentData({...documentData, [field]: value})} isChanging={isChanging} canSubmit={doSubmit} formData={formData} details={member} />,

        }
    ];

    const setDetailType = key => {
        switch (key) {
            case '1':
                setEditType('profile')
                setFormData({...formData, detail_type: 'STAFF-INFO'})
                break;
            case '2':
                setEditType('profile')
                setFormData({...formData, detail_type: 'WORK-INFO'})
                break;
            case '3':
                setEditType('profile')
                setFormData({...formData, detail_type: 'BANK-INFO'})
                break;
            case '4':
                setEditType('profile')
                setFormData({...formData, detail_type: 'NOK-INFO'})
                break;
            case '5':
                setEditType('profile')
                setFormData({...formData, detail_type: 'CONTACT-INFO'})
                break;
            case '6':
                setEditType('document')
                setFormData({...formData, detail_type: 'DOCUMENT-INFO'})
                break;
            default:
                break;
        }
    }


    
    return (
        <Card loading={isLoading}>
            {/* <div className="card-header">
                <h3 className="card-title">Staff Information</h3>
               
            </div> */}
            <div className="card-body">
                <Tabs defaultActiveKey="1" items={tabs} onChange={setDetailType} />
            </div>
            <div className="card-footer d-flex justify-content-between text-right">
                <Button className="btn btn-secondary" onClick={onPrevious} disabled={step === 0}>
                    Cancel
                </Button>
                
                <Button className="btn btn-primary" onClick={doSubmit}>
                    Save
                </Button>
                
            </div>
        </Card>
    )
}