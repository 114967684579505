import React, { useState } from "react";
import { LoanDetails } from "../../MemberArea/Components/LoanDetails";
import Card from "../../common/Card";
import { useGetMemberLoansQuery, useMarkLoanAsCompletedMutation, useMarkLoanAsIncompleteMutation  } from "../../../services/member.service";
import { formatNumber } from "../../../utils/helpers";
import { Empty, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleDelete } from "../../../redux/slices/ui.slice";
import { DELETE_LOAN, DOWNLOAD_LOAN_FORM } from "../../../services/CONSTANTS";

export const MemberLoanList = ({coop, member}) => {
    const {data, isLoading} = useGetMemberLoansQuery({coopId: coop?.id, id: member?.coop_id}, {skip: !member});
    const [markComplete, {isLoading: loadingComplete, isSuccess: completeSuccess }] = useMarkLoanAsCompletedMutation()
    const [markInComplete, {isLoading: loadingIncomplete, isSuccess: inCompleteSuccess }] = useMarkLoanAsIncompleteMutation()
    const {token, user} = useSelector(state => state.user);

    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();

    const deleteLoan = (e, id) => {
        dispatch(
          toggleDelete({
            open: true,
            title: 'Loan Application',
            url: `${DELETE_LOAN}?loan_id=${id}`,
            // ele: JSON.stringify(e.currentTarget),
            parent: 'tr'
          })
        );
    }

    const downloadLoan = async (row) => {
        // setLoading(true);
        const url = `${process.env.REACT_APP_API_URL}${DOWNLOAD_LOAN_FORM}?loan_id=${row.id}`
        await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            }})
            .then((response) => response.blob())
            .then((myBlob) => {
                const objectURL = URL.createObjectURL(myBlob);
                const anchor = document.createElement("a");
                anchor.href = objectURL; // Set the href to the data URL
                anchor.target = "_self"; // Open in a new tab
                anchor.download = `${member.firstname} ${member.lastname}-${row.product.name}-form.pdf`; // Specify the file name
      
                // Trigger a click event on the anchor element
                anchor.click();
                anchor.remove();
            }).catch(err => console.log(err.message));
        // setLoading(false)
    }

    return (
       
            <div className={`table-responsive dimmer ${isLoading ? 'active' : ''}`}>
                <div className="loader" />
                <table className="table table-striped table-vcenter table-hover mb-0 dimmer-content">
                    <thead>
                        <tr>
                            <th>Loan Name</th>
                            <th>Amount</th>
                            <th>Total Loan</th>
                            <th>Status</th>
                            <th>Payment</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.data?.map(row => 
                            <tr key={`member-loan-${row.id}`}>
                                <td>{row.product.name}</td>
                                <td><div className="font-15">{formatNumber(row.loan_amount)}</div></td>
                                <td><div className="font-15">{formatNumber(row.total_amount)}</div></td>
                                <td>
                                    {row.status === 0 && <span className="tag tag-warning">Pending</span>}
                                    {row.status === 1 && <span className="tag tag-blue">Approved</span>}
                                    {row.status === 2 && <span className="tag tag-red">Denied</span>}
                                </td>
                                <td>
                                    {row.is_paid === 0 && <span className="tag tag-warning">Running</span>}
                                    {row.is_paid === 1 && <span className="tag tag-success">Completed</span>}
                                </td>
                                <td>
                                    <Tooltip title={'View Details'}>
                                        <button type="button" className="btn btn-icon" title="Edit" onClick={() => setSelected(row)} data-toggle="modal" data-target="#loan-details">
                                            <i className="fa fa-eye text-info" />
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Download loan form">
                                        <button type="button" className="btn btn-icon" title="Download" onClick={() => downloadLoan(row)}>
                                            <i className="fa fa-download text-info" />
                                        </button>
                                    </Tooltip>
                                    {row.is_paid === 1 && user?.role_name == 'cooperative-admin' &&
                                        <Tooltip title="Mark as running" >
                                            <button 
                                                type="button"
                                                className="btn btn-icon"
                                                onClick={() => markInComplete(row.id)}
                                                title="Edit"
                                            >
                                                    <i className="fa fa-angle-double-right text-warning" />
                                                </button>
                                        </Tooltip>
                                    }
                                    {row.is_paid === 1 && user?.role_name == 'cooperative-admin' &&
                                        <Tooltip title="Mark as completed">
                                            <button 
                                                type="button" 
                                                className="btn btn-icon" 
                                                onClick={() => markComplete(row.id)}
                                            >
                                                <i className="fa fa-calendar-check-o text-success" />
                                            </button>
                                        </Tooltip>
                                    }
                                    {user?.role_name == 'cooperative-admin' &&

                                        <Tooltip title="Delete loan">
                                            <button onClick={(e) => deleteLoan(e, row.id)} type="button" className="btn btn-icon" data-target="#deleteModal" data-toggle="modal">
                                                <i className="fa fa-trash-o text-danger" />
                                            </button>
                                        </Tooltip>
                                    }
                                </td>
                            </tr>
                        )}

                        {data?.data.length === 0 && <tr><td colSpan={5}><Empty /></td></tr>}
                        
                    </tbody>
                </table>
                <LoanDetails loan={selected} />
            </div>
    )
}