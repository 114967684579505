import {
    addDoc,
    collection,
    serverTimestamp,
    orderBy,
    query,
    setDoc,
    doc,
} from "firebase/firestore";

import { db } from "../../firebase";



const saveNewThread = async (data) => {
    // save new thread
    await setDoc( 
        doc(
            db,
            "private_chats",
            data.senderId,
            "members",
            data.receiverId,
        ), {
            displayName: data.receiverName,
            createdAt: serverTimestamp()
        });

        await setDoc( 
            doc(
                db,
                "private_chats",
                data.receiverId,
                "members",
                data.senderId,
            ), {
                displayName: data.senderName,
                createdAt: serverTimestamp()
            });
}

const saveMessage = async (data) => {
    // 
    if (data.isNew) //save new thread
        await saveNewThread(data);
        
    // save sender message
    await addDoc(
        collection(
            db,
            "private_chats",
            data.senderId,
            "members",
            data.receiverId,
            "messages"
        ),
        {
            username: data.receiverName,
            messageUserId: data.senderId,
            message: data.message,
            createdAt: serverTimestamp(),
        }
    );
    // save reciever message
    await addDoc(
        collection(
            db,
            "private_chats",
            data.receiverId,
            "members",
            data.senderId,
            "messages"
        ),
        {
            username: data.senderName,
            messageUserId: data.senderId,
            message: data.message,
            createdAt: serverTimestamp(),
        }
    );
}

const getMessages = (userId, receiverId) => {

    return query(
        collection(
            db,
            "private_chats",
            userId,
            "members",
            receiverId,
            "messages"
        ),
        orderBy("createdAt")
    );


}

const getRecentMessages = (userId) => {
    return query(
        collection(
            db,
            "private_chats",
            userId,
            "members"
        ),
        orderBy("createdAt", "desc")
    );
}

export {saveMessage, getRecentMessages}