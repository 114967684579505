import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { PersonalInfo } from "./Components/PersonalInfo";
import { StaffInfo } from "../Members/Forms/StaffInfo";
import { WorkInfo } from "../Members/Forms/WorkInfo";
import { BankDetails } from "../Members/Forms/BankDetails";
import { NokDetails } from "../Members/Forms/NoKDetails";
import { EmergencyContact } from "../Members/Forms/EmergencyContact";
import { useEditMemberDetailsMutation, useGetCoopMemberQuery, useUploadDocumentMutation } from "../../services/member.service";
import alertify from 'alertifyjs';
import dayjs from "dayjs";
import { DocumentsForm } from "../Members/Forms/Documents";

export const EditProfile = () => {
    const {isFixNavbar: fixNavbar} = useSelector(state => state.settings);
    const {activeCoop} = useSelector(state => state.coop);
    const {user} = useSelector(state => state.user);
	const [member, setMember] = useState(null);
	const {data} = useGetCoopMemberQuery({coop_id: activeCoop?.id, member_id: user?.id});
    const [documentData, setDocumentData] = React.useState({
        avatar: null,
        signature: null,
        reg_form: null
    })

    const [isChanging, setIsChanging] = React.useState(false);
    const [save, {isLoading, isSuccess, isError, data: saveData, error}] = useEditMemberDetailsMutation();
    const [doUpload, {
        isLoading: isUploading, 
        isSuccess: uploadSuccess, 
        isError: uploadError, 
        data: uploadData, 
        error: uploadErrData
    }] = useUploadDocumentMutation();

    useEffect(() => {
		if (data) {
			setMember(data?.data);
		}

		if (isError) {
			alertify.error(error.message);
		}
	}, [data]);

    useEffect(() => {
        if(uploadSuccess && uploadData?.status) {
            // resetForm();
            alertify.success(uploadData?.message)
        }

        if (uploadErrData) {
            //showToast()
            alertify.error(uploadErrData?.message)
        }
    }, [uploadSuccess, uploadErrData, uploadData]);


    const [formData, setFormData] = useState(
        {
            cooperative_id: activeCoop?.id,
            email: "",
            firstname: "",
            middlename: "",
            lastname: "",
            staff_id: "",
            date_joined: "",
            marital_status:  "",
            gender:  "",
            dob: "",
            religion: "",
            current_address:  "",
            role_id: "",
            savings_amount: "",
            country_id: 0,
            state_id: 0,
            level: "",
            department_id: "",
            official_email: "",
            official_phone: "",
            bank_id: "",
            branch: "",
            sort_code: "",
            account_type: "",
            account_name: "",
            account_no:  "",
            nok_name: "",
            nok_address: "",
            nok_phone:  "",
            nok_email: "",
            contact_name: "",
            contact_address:  "",
            contact_phone: "",
            contact_email: ""
        }
    )

    const doSubmit = (detail_type) => {
        const payload = {...formData};
        payload.detail_type = detail_type;
        payload.dob = dayjs(formData.dob).format('YYYY-MM-DD')
        payload.date_joined = dayjs(formData.date_joined).format('YYYY-MM-DD')
        save(payload)
    }

    const uploadDocument = () => {
        const formData = new FormData();
        formData.append('coop_id', user?.coop_id);

        if (documentData.avatar)
            formData.append('avatar', documentData.avatar);
        if(documentData.reg_form)
            formData.append('reg_form', documentData.reg_form);
        if(documentData.signature)
            formData.append('signature', documentData.signature);

        doUpload(formData);
    }
    
    useEffect(() => {
        if(isSuccess && data?.status) {
            // resetForm();
            alertify.success(data?.message)
        }

        if (isError) {
            //showToast()
            alertify.error(error?.message || 'Something went wrong. Unable to process request')
        }
        setIsChanging(false);
    }, [isSuccess, isError, data]);

     useEffect(() => {
        if (member) 
            setFormData({
                cooperative_id: activeCoop?.id,
                coop_id: user?.coop_id,
                email: member?.user?.email || "",
                phone: member?.phone || "",
                firstname: member?.firstname || "",
                middlename: member?.middlename || "",
                lastname: member?.lastname || "",
                staff_id: member?.staff_id || "",
                date_joined: member?.date_joined || "",
                marital_status: member?.marital_status || "",
                gender: member?.gender || "",
                dob: member?.dob || "",
                religion: member?.religion || "",
                current_address: member?.current_address || "",
                role_id: member?.role_id || "",
                savings_amount: member?.savings_amount || "",
                country_id: member?.country_id || "",
                state_id: member?.state_id || "",
                level: member?.workInfo.level || "",
                department_id: member?.workInfo.department_id || "",
                official_email: member?.workInfo.official_email || "",
                official_phone: member?.workInfo.official_email || "",
                bank_id: member?.bankInfo.bank_id || "",
                branch: member?.bankInfo.branch || "",
                sort_code: member?.bankInfo.sort_code || "",
                account_type: member?.bankInfo.account_type || "",
                account_name: member?.bankInfo.account_name || "",
                account_no: member?.bankInfo.account_no || "",
                nok_name: member?.nokInfo.name || "",
                nok_address: member?.nokInfo.address || "",
                nok_phone: member?.nokInfo.phone || "",
                nok_email: member?.nokInfo.email || "",
                contact_name: member?.emergencyInfo.name || "",
                contact_address: member?.emergencyInfo.address || "",
                contact_phone: member?.emergencyInfo.phone || "",
                contact_email: member?.emergencyInfo.email || ""
            })
    }, [member]);

    const triggerSubmit = (id) => setIsChanging(true);

    return (
        <div className={`section-body ${fixNavbar ? "marginTop" : ""} `}>
            <div className="container-fluid px-0">
                <div className="row clearfix">
                    <div className="col-lg-3 col-md-12">
                        <PersonalInfo member={member} />
                    </div>
                    <div className="col-lg-9 col-md-12">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        id="Employee-tab"
                                        data-toggle="tab"
                                        href="#personal-info"
                                    >
                                        Personal Info
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="Employee-tab"
                                        data-toggle="tab"
                                        href="#work-info"
                                    >
                                        Work Info
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="Employee-tab"
                                        data-toggle="tab"
                                        href="#next-of-kin"
                                    >
                                        Next of Kin
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="Employee-tab"
                                        data-toggle="tab"
                                        href="#emg-contact"
                                    >
                                        Emergenct Contact
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        id="Employee-tab"
                                        data-toggle="tab"
                                        href="#documents"
                                    >
                                        Documents
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className={`container-fluid px-0 dimmer ${isLoading ? 'active' : ''}`}>
                            <div className="loader" />
                            <div className="tab-content dimmer">
                                <div className="tab-pane fade show active" id="personal-info" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <StaffInfo 
                                                onChange={(field, value) => setFormData({...formData, [field]: value})} 
                                                isChanging={isChanging} 
                                                canSubmit={doSubmit} 
                                                formData={formData} 
                                                details={member} 
                                            />
                                        </div>
                                        <div className="card-footer text-right">
                                            <button type="button" className="btn btn-primary" onClick={() => triggerSubmit('staff-info-btn')}>Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="work-info" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <WorkInfo  coop={activeCoop} onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} formData={formData} canSubmit={doSubmit} details={member} />
                                        </div>
                                        <div className="card-footer text-right">
                                            <button type="submit" className="btn btn-primary" onClick={() => triggerSubmit('work-info-btn')}>Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="bank-details" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <BankDetails onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={doSubmit} formData={formData} details={member} />
                                        </div>
                                        <div className="card-footer text-right">
                                            <button type="submit" className="btn btn-primary" onClick={() => triggerSubmit('bank-details-btn')}>Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="next-of-kin" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <NokDetails onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={doSubmit} formData={formData} details={member} />
                                        </div>
                                        <div className="card-footer text-right">
                                            <button type="submit" className="btn btn-primary" onClick={() => triggerSubmit('nok-details-btn')}>Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="emg-contact" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <EmergencyContact onChange={(field, value) => setFormData({...formData, [field]: value})} isChanging={isChanging} canSubmit={doSubmit} formData={formData} details={member} />
                                        </div>
                                        <div className="card-footer text-right">
                                            <button type="submit" className="btn btn-primary" onClick={() => triggerSubmit('emergency-contact-btn')}>Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="documents" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <DocumentsForm onChange={(field, value) => setDocumentData({...documentData, [field]: value})} formData={documentData} />
                                        </div>
                                        <div className="card-footer text-right">
                                            <button type="submit" className="btn btn-primary" onClick={uploadDocument}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}