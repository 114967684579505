import React, { useEffect, useRef, useState } from 'react'
import {useSelector } from 'react-redux';
import { ChatLists } from '../Chats/ChatLists';
import { SendMessage } from '../Chats/SendMessage';
import {
    query,
    collection,
    orderBy,
    onSnapshot,
    limit,
  } from "firebase/firestore";
import { db } from '../../firebase';
import { useGetCoopMemberQuery } from '../../services/member.service';
import alertify from 'alertifyjs';

const AppChart = () => {
    const [messages, setMessages] = useState([]);
    const [member, setMember] = useState(null);
    const [isNew, setIsNew] = useState(false);
    const { user } = useSelector(state => state.user);
    const { activeCoop } = useSelector(state => state.coop);
    const {activeChat} = useSelector(state => state.ui);
    const scroll = useRef();
    const {data, isLoading, isError, error} = useGetCoopMemberQuery({
        coop_id: activeCoop?.id, 
        member_id: user?.id
    }, {
        skip: !user
    });

    useEffect(() => {
		if (data) {
			setMember(data?.data);
		}

		if (isError) {
			alertify.error(error.message);
		}
	}, [data]);

    useEffect(() => {
        if (activeChat) {
            const unsub = onSnapshot(
                query(
                    collection(
                        db,
                        "private_chats",
                        user?.coop_id,
                        "members",
                        activeChat?.coopId,
                        "messages"
                    ),
                    orderBy("createdAt")
                ),
                (snapshot) => {
                    if (!snapshot.docs.length) {
                        setIsNew(true);
                    } else {
                        setIsNew(false);
                    }
                    setMessages(
                        snapshot.docs.map((doc) => ({
                            ...doc.data(),
                            id: doc.id,
                        }))
                    );
                }
            );
            return () => unsub;
        }
    }, [activeChat?.coopId]);

    return (
        <>
            <div className="section-light py-3 chat_app">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="card bg-none b-none">
                                {activeChat && 
                                <div className="card-header bline pt-1">
                                    <h3 className="card-title">{activeChat?.displayName} <small>Last seen: 2 hours ago</small></h3>
                                    <div className="card-options">
                                        <a href="/#" className="p-1 chat_list_btn"><i className="fa fa-align-right" /></a>
                                        <a href="/#" className="p-1"><i className="fa fa-plus" /></a>
                                        <a href="/#" className="p-1"><i className="fa fa-cog" /></a>
                                        <a href="/#" className="p-1"><i className="fa fa-refresh" /></a>
                                    </div>
                                </div>}
                                <div className="chat_windows">
                                    <ul className="mb-0">
                                        {messages.map(message => 
                                            <li className={`${message.messageUserId === user.coop_id ? 'my-message' : 'other-message'} mb-0`} key={message.id}>
                                                {/* <img className="avatar mr-3" src="../assets/images/xs/avatar2.jpg" alt="avatar" /> */}
                                                <div className="message">
                                                    <p className={`${message.messageUserId === user.coop_id ? 'bg-light-gray' : 'bg-light-cyan'}`}>{message.message}</p>
                                                    {/* <span className="time">10:10 AM, Today</span> */}
                                                </div>
                                            </li>
                                        )}
                                        {/* <li className="other-message">
                                            <img className="avatar mr-3" src="../assets/images/xs/avatar3.jpg" alt="avatar" />
                                            <div className="message">
                                                <p className="bg-light-cyan">Hi Aiden, how are you? How is the project coming along?</p>
                                                <p className="bg-light-cyan">Are we meeting today?</p>
                                                <span className="time">10:15 AM, Today</span>
                                            </div>
                                        </li>
                                        <li className="my-message">
                                            <div className="message">
                                                <p className="bg-light-gray">Project has been already finished and I have results to show you.</p>
                                                <div className="file_folder">
                                                    <a href="/#;">
                                                        <div className="icon">
                                                            <i className="fa fa-file-excel-o text-success" />
                                                        </div>
                                                        <div className="file-name">
                                                            <p className="mb-0 text-muted">Report2017.xls</p>
                                                            <small>Size: 68KB</small>
                                                        </div>
                                                    </a>
                                                    <a href="/#;">
                                                        <div className="icon">
                                                            <i className="fa fa-file-word-o text-primary" />
                                                        </div>
                                                        <div className="file-name">
                                                            <p className="mb-0 text-muted">Report2017.doc</p>
                                                            <small>Size: 68KB</small>
                                                        </div>
                                                    </a>
                                                </div>
                                                <span className="time">10:17 AM, Today</span>
                                            </div>
                                        </li>
                                        <li className="other-message">
                                            <img className="avatar mr-3" src="../assets/images/xs/avatar4.jpg" alt="avatar" />
                                            <div className="message">
                                                <div className="media_img">
                                                    <img src="../assets/images/gallery/1.jpg" className="w150 img-thumbnail" alt="avatar" />
                                                    <img src="../assets/images/gallery/2.jpg" className="w150 img-thumbnail" alt="avatar" />
                                                </div>
                                                <span className="time">10:15 AM, Today</span>
                                            </div>
                                        </li>
                                        <li className="other-message">
                                            <span className="avatar avatar-blue mr-3">NG</span>
                                            <div className="message">
                                                <p className="bg-light-pink">Are we meeting today I have results?</p>
                                                <p className="bg-light-pink">Project has been already finished and to show you.</p>
                                                <span className="time">10:18 AM, Today</span>
                                            </div>
                                        </li>
                                        <li className="my-message">
                                            <div className="message">
                                                <p className="bg-light-gray">Well we have good budget for the project</p>
                                                <span className="time">10:25 AM, Today</span>
                                            </div>
                                        </li>
                                        <li className="my-message">
                                            <div className="message">
                                                <div className="media_img">
                                                    <img src="../assets/images/gallery/3.jpg" className="w100 img-thumbnail" alt="avatar" />
                                                    <img src="../assets/images/gallery/4.jpg" className="w100 img-thumbnail" alt="avatar" />
                                                    <img src="../assets/images/gallery/5.jpg" className="w100 img-thumbnail" alt="avatar" />
                                                    <img src="../assets/images/gallery/6.jpg" className="w100 img-thumbnail" alt="avatar" />
                                                </div>
                                                <span className="time">10:25 AM, Today</span>
                                            </div>
                                        </li> */}
                                    </ul>
                                    <span ref={scroll} />
                                    <SendMessage isNew={isNew} user={member} receiver={activeChat} scroll={scroll} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChatLists activeCoop={activeCoop?.id} userId={user?.coop_id} />
            </div>
        </>
    )
    
}


export default AppChart;