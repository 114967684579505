import React from "react";
import InitialsAvatar from 'react-initials-avatar';
import { useDispatch } from "react-redux";
import { setActiveChat } from "../../redux/slices/ui.slice";

export const MemberChatsList = ({members}) => {
    const dispatch = useDispatch();
    return (
        <ul className="right_chat list-unstyled list">
            {members.length > 0 && members.map(member => 
                <li className="online" key={member.id}>
                    <a href="javascript:;" onClick={() => dispatch(setActiveChat({
                        coopId: member.coop_id,
                        displayName: `${member.firstname} ${member.lastname}`
                    }))}>
                        <div className="media">
                            <span
                                className="media-object avatar avatar-blue"
                                data-toggle="tooltip"
                                data-original-title="Avatar Name"
                            >
                                <InitialsAvatar name={`${member.firstname} ${member.lastname}`} />
                            </span>
                            <div className="media-body">
                                <span className="name text-capitalize">{`${member.firstname} ${member.lastname}`}</span>
                                <span className="message">{member.coop_id}</span>
                                {/* <span className="badge badge-outline status" /> */}
                            </div>
                        </div>
                    </a>
                </li>
            )}
            
        </ul>
    )
}