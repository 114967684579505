import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import { useActivateMemberMutation, useDeactivateMemberMutation, useLazyGetCoopMembersQuery } from "../../services/member.service";
import { Empty, Tooltip } from "antd";
import { formatNumber } from "../../utils/helpers";
import InitialsAvatar from 'react-initials-avatar';
import Card from "../common/Card";
import { Pagination } from "../Pagination/Pagination";

export const MemberTable = ({activeCoop}) => {
    const [pagination, setPagination] = useState({
        total: 0,
        perPage: 0,
        currentPage: 1,
        first: 1,
        last: 1
    });

    const [members, setMembers] = useState([]);
    const [getResults, {data, isFetching}] = useLazyGetCoopMembersQuery()
    const [deactivateMember, {data: deactivateData, isLoading: isDeactivating}] = useDeactivateMemberMutation()
    const [activateMember, {data: activateData, isLoading: isActivating}] = useActivateMemberMutation()

    const [filterData, setFilterData] = useState({
        coopId: activeCoop?.id,
        page: 1,
        search: '',
    });

    useEffect(() => {
        if (activeCoop) getResults(filterData);

    }, [activeCoop]);

    useEffect(() => {
        if (data?.status) {
            setMembers(data?.data?.data);

            setPagination({
                total: data?.data?.meta?.total,
                perPage: data?.data?.meta?.per_page,
                currentPage: data?.data?.meta?.current_page,
                first: data.data.meta?.first_page,
                last: data.data.meta?.last_page
            })
        }
    }, [data]);

    const calculateBalance = savings => {
        let total = 0;
        savings.forEach(element => {
            total += element.balance
        });
        return formatNumber(total);
    }

    const calculateAvailBalance = savings => {
        let total = 0;
        savings.forEach(element => {
            total += element.balance - element.lien;
        });
        return formatNumber(total);
    }

    const calculateTotalSavings = savings => {
        let total = 0;
        savings.forEach(element => {
            total += element.savings_amount
        });
        return formatNumber(total);
    }

    const doSearch = (e) => {
        e.preventDefault();
        getResults(filterData)
    }
    
    return (
        <Card loading={isFetching} title={`Employee List`} headerOptions={
            <div className="card-options">
                <form onSubmit={doSearch}>
                    <div className="input-group">
                        <input
                            type="text"
                            onChange={(e) => setFilterData({...filterData, search: e.target.value})}
                            className="form-control form-control-sm"
                            placeholder="Search something..."
                            name="s"
                        />
                        <span className="input-group-btn ml-2">
                            <button className="btn btn-icon btn-sm" type="submit">
                                <span className="fe fe-search" />
                            </button>
                        </span>
                    </div>
                </form>
            </div>
        }>
            <div className="card-body dimmer-content">
                <div className="table-responsive">
                    <table className="table table-hover table-striped table-vcenter text-nowrap mb-0">
                        <thead>
                            <tr>
                                {/* <th>#</th> */}
                                <th>Name</th>
                                <th>Department</th>
                                <th>Leg. Balance</th>
                                <th>Avl. Balance</th>
                                <th>Monthly Sav.</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members?.map(row => 
                                <tr key={`member-${row.id}`}>
                                    {/* <td className="w40">
                                        <label className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                name="example-checkbox1"
                                                defaultValue="option1"
                                            />
                                            <span className="custom-control-label">
                                                &nbsp;
                                            </span>
                                        </label>
                                    </td> */}
                                    <td className="d-flex">
                                        <span
                                            className="avatar avatar-blue"
                                            data-toggle="tooltip"
                                            data-original-title="Avatar Name"
                                        >
                                            <InitialsAvatar name={`${row.firstname} ${row.lastname}`} />
                                        </span>
                                        <NavLink to={`/members/view?member_id=${row.id}`} className="ml-3">
                                            <h6 className="mb-0">
                                                {row.firstname} {row.lastname} &nbsp;
                                                ({row.coop_id})
                                            </h6>
                                            <span className="text-muted">
                                                {row.user.email}
                                            </span>
                                        </NavLink>
                                    </td>
                                    <td>
                                        <span>{row.workInfo?.department?.name || 'N/A'}</span>
                                    </td>
                                    <td>
                                        <span>{calculateBalance(row.savings)}</span>
                                    </td>
                                    <td>
                                        <span>{calculateAvailBalance(row.savings)}</span>
                                    </td>
                                    <td>
                                        <span>{calculateTotalSavings(row.savings)}</span>
                                    </td>
                                    <td>
                                        <span className="tag tag-success ml-0 mr-0">Approved</span>
                                    </td>
                                    <td>
                                        <NavLink
                                            to={`/members/view?member_id=${row.id}`}
                                            className="btn btn-icon btn-sm"
                                            title="View"
                                        >
                                            <i className="fa fa-eye" />
                                        </NavLink>
                                        {(row.status === 2 || row.status === 0) &&
                                            <Tooltip title="Activate">
                                                <button
                                                    type="button"
                                                    className="btn btn-icon btn-sm"
                                                    title="Edit"
                                                    onClick={() => activateMember({cooperative_id: activeCoop?.id, coopId: row.coop_id})}
                                                >
                                                    <i className="fe fe-check-circle text-success" />
                                                </button>
                                            </Tooltip>
                                        }
                                        {row.status === 1 && 
                                            <Tooltip title="Deactivate">
                                                <button
                                                    type="button"
                                                    className="btn btn-icon btn-sm js-sweetalert"
                                                    title="Delete"
                                                    data-type="confirm"
                                                    onClick={() => deactivateMember({cooperative_id: activeCoop?.id, coopId: row.coop_id})}
                                                >
                                                    <i className="fe fe-x-square text-danger" />
                                                </button>
                                            </Tooltip>
                                        }
                                    </td>
                                </tr>
                            )}

                            {data && data?.data.meta.total === 0 && <tr><td colSpan={8}><Empty /></td></tr>}
                            
                            
                        </tbody>
                    </table>
                </div>
                {pagination.last > 1 && 
                    <Pagination 
                        {...pagination} 
                        changePage={(page) => {
                            getResults({
                                coopId: activeCoop?.id,
                                page,
                                search: filterData.search,
                            })
                            setFilterData({...filterData, page});
                        }} 
                    />
                }
            </div>
        </Card>
    )
}