import React, { useEffect, useState } from "react";
import { MemberChatsList } from "./MemberChatsList";
import { useLazyGetCoopMembersQuery } from "../../services/member.service";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import InitialsAvatar from 'react-initials-avatar';
import { db } from "../../firebase";
import { useDispatch } from "react-redux";
import { setActiveChat } from "../../redux/slices/ui.slice";

export const ChatLists = ({activeCoop, userId}) => {
    const [activeTab, setActiveTab] = useState(1);
    const [members, setMembers] = useState([])
    const [recentChats, setRecentChats] = useState([]);
    const [filteredChats, setFiltered] = useState([]);
    const [search, setSearch] = useState('');
    const [getResults, {data, isFetching}] = useLazyGetCoopMembersQuery()
    const dispatch = useDispatch();

    useEffect(() => {
        getResults({
            coopId: activeCoop,
            page: 1,
            search: '',
        })
    }, []);

    useEffect(() => {
        const q = query(
            collection(
                db,
                "private_chats",
                userId,
                "members"
            ),
            orderBy("createdAt", "desc")
        );
    
        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            const fetchedMessages = [];
            QuerySnapshot.forEach((doc) => {
                fetchedMessages.push({ ...doc.data(), id: doc.id });
            });
            
            setRecentChats(fetchedMessages);
            setFiltered(fetchedMessages);
        });

        return () => unsubscribe;
        
      }, [userId]);

    useEffect(() => {
        if (data && data.status)
            setMembers(data?.data?.data);

    }, [data]);

    const searchUser = (e) => {
        const value = e.target.value;
        if (activeTab === 1) {

        } else {
            getResults({
                coopId: activeCoop,
                page: 1,
                search: value,
            })
        }
    }

    return (
        <div className="chat_list section-white" id="users">
            <a href="/#" className="chat_list_btn float-right"><i className="fa fa-align-right" /></a>
            <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" onClick={() => setActiveTab(1)} id="users-tab" data-toggle="tab" href="#users-list" role="tab" aria-controls="users-list" aria-selected="true">Recent</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" onClick={() => setActiveTab(2)} id="groups-tab" data-toggle="tab" href="#groups" role="tab" aria-controls="groups" aria-selected="false">New</a>
                </li>
            </ul>
            <div className="input-group mt-2 mb-2">
                <input type="text" onChange={searchUser} className="form-control search" placeholder="Search..." />
            </div>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="users-list" role="tabpanel" aria-labelledby="users-tab">
                    <ul className="right_chat list-unstyled list">
                       {filteredChats.map((chat, i) => 
                        <li className="online" key={`chat-${i}`}>
                                <a href="javascript:;" onClick={() => dispatch(setActiveChat({
                                    coopId: chat.id,
                                    displayName: chat.displayName
                                }))}>
                                    <div className="media">
                                        <span
                                            className="media-object avatar avatar-blue"
                                            data-toggle="tooltip"
                                            data-original-title="Avatar Name"
                                        >
                                            <InitialsAvatar name={`${chat.displayName}`} />
                                        </span>
                                        <div className="media-body">
                                            <span className="name text-capitalize">{chat.displayName}</span>
                                            <span className="message">Are we meeting today?</span>
                                            <span className="badge badge-outline status" />
                                        </div>
                                    </div>
                                </a>
                            </li>
                        )}
                       
                    </ul>
                </div>
                <div className="tab-pane fade" id="groups" role="tabpanel" aria-labelledby="groups-tab">
                    <MemberChatsList members={members} />
                </div>
            </div>
        </div>
    )
}