import React from "react";
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

export const DocumentsForm = ({onChange, formData}) => {
    const profilePicProps = {
        name: 'profilePic',
        multiple: false,
        maxCount: 1,
        accept: '.jpeg,.jpg,.png',
        beforeUpload(file) {
            // console.log('before upload', file);
            onChange('avatar', file);
            return false;
        },
        onDrop(e) {
          console.log('Dropped files profile pic', e.dataTransfer.files);
        },
    };

    const signatureProps = {
        name: 'signature',
        multiple: false,
        maxCount: 1,
        accept: '.jpeg,.jpg,.png',
        beforeUpload(file) {
            // console.log('before upload', file);
            onChange('signature', file);
            return false;
        },
        onDrop(e) {
          console.log('Dropped files signature', e.dataTransfer.files);
        },
    };

    const registrationFormProps = {
        name: 'registrationForm',
        multiple: false,
        maxCount: 1,
        accept: '.jpeg,.jpg,.png,.pdf',
        beforeUpload(file) {
            // console.log('before upload', file);
            onChange('reg_form', file);
            return false;
        },
        onDrop(e) {
          console.log('Dropped files registration form', e.dataTransfer.files);
        },
    };

    
    
    return (
        <div className="card">
            <div className="card-body dimmer-content">
                <div className=" row mb-5">
                    <div className="col-10">
                        <Dragger {...profilePicProps}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload profile pic</p>
                            {/* <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                            </p> */}
                        </Dragger>
                    </div>
                    <div className="col-2" style={{border: '1px dashed #d9d9d9', borderRadius: '6px'}}>

                    </div>
                </div>
                <div className="row mt-2 mb-5">
                    <div className="col-10">
                        <Dragger {...signatureProps}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload signature</p>
                            {/* <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                            </p> */}
                        </Dragger>
                    </div>
                    <div className="col-2" style={{border: '1px dashed #d9d9d9', borderRadius: '6px'}}>
                        
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-10">
                        <Dragger {...registrationFormProps}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload registration form</p>
                            {/* <p className="ant-upload-hint">
                            Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                            banned files.
                            </p> */}
                        </Dragger>
                    </div>
                    <div className="col-2" style={{border: '1px dashed #d9d9d9', borderRadius: '6px'}}>
                        
                    </div>
                </div>
            </div>
           
        </div>
    )
}