import React, { useState } from "react";
import { saveMessage } from "../../services/api/firebase-api";


export const SendMessage = ({isNew, user, scroll, receiver}) => {
    const [message, setMessage] = useState("");

    const sendMessage = async () => {
        if (message.trim() === "")
          return;
        
        try {
            if (receiver) {
                saveMessage({
                    senderId: user?.coop_id || 'COOP001',
                    receiverId: receiver.coopId,
                    senderName: user ? `${user?.firstname} ${user?.lastname}` : 'Admin',
                    receiverName: receiver?.displayName,
                    message,
                    isNew
                })
            }
        } catch (error) {
            console.log(error);
        }
        setMessage("");
        scroll.current.scrollIntoView({ behavior: "smooth" });
      };

    return (
        <div className="chat-message clearfix">
            <a href="/#;"><i className="icon-camera" /></a>
            <a href="/#;"><i className="icon-camcorder" /></a>
            <a href="javascript:;" onClick={sendMessage}><i className="icon-paper-plane" /></a>
            <div className="input-group mb-0">
                <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Enter text here..." 
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyUp={(e) => {
                        if(e.key === 'Enter') 
                            sendMessage()
                    }}
                />
            </div>
        </div>
    )
}